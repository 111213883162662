import React from 'react'
import { useIntl } from 'react-intl'
import { Card } from '@braze/web-sdk'
import { SideFlyoutContainer, Icons } from 'msp-components'
import { useBraze } from 'msp-integrations'
import { NotificationFlyoutProps } from './NotificationFlyout.types'

const NotificationFlyout = ({
  className,
  isOpen,
  onOpen,
  onClose
}: NotificationFlyoutProps) => {
  const { formatMessage } = useIntl()
  const {
    showContentCards,
    hideContentCards,
    notificationQuantity,
    updateUnreadNotificationCount
  } = useBraze()

  const filterNotifications = (cards: Card[]) => {
    return cards.filter((card) => card.extras?.type === 'notification')
  }

  const initializeBrazeNotificationArea = () => {
    const notificationArea = document.getElementById(
      'content-area-notifications'
    )
    if (notificationArea) {
      showContentCards(notificationArea, filterNotifications)
    }
  }

  const handleOpen = () => {
    initializeBrazeNotificationArea()
    onOpen && onOpen()
  }

  const handleClose = () => {
    updateUnreadNotificationCount()
    onClose && onClose()
    hideContentCards()
  }

  return (
    <SideFlyoutContainer
      className={className}
      isOpen={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      position='right'
      titleContent={
        <div className='flex flex-row items-center space-x-1 whitespace-nowrap stroke-black stroke-1'>
          <div>
            {formatMessage({
              id: 'msp.notifications_flyout.title',
              defaultMessage: 'Notifications'
            })}
          </div>
          <Icons iconName={'BellEmpty'} height={'13px'} width={'13px'} />
          <div>{`(${notificationQuantity})`}</div>
        </div>
      }
    >
      <div className='min-h-1 w-full bg-black'></div>
      <div
        className='bg-lightGray h-full w-auto overflow-auto'
        id='content-area-notifications'
      />
    </SideFlyoutContainer>
  )
}

export default NotificationFlyout
